import React from "react";
// import Cloud from "../assets/images/cloud-header.jpg";
import Cloud from "../../assets/images/cloud-header.jpg";

const ClippedGradientHeader = () => {
  const myHeader = {
    backgroundImage: `url(${Cloud})`,
    height: "85vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 90%)`,
    backgroundPosition: "center",
  };
  return (
    <>
      <div style={myHeader}>
        <div className="gradient-image-container">
          <div className="text-box">
            <h1 className="heading-primary">
              <span className="heading-primary-main">Gradient Ascent</span>
              <span className="heading-primary-sub">
                Full Service <br />
                Digital Agency
              </span>
            </h1>
            <a href="#contact-us" className="btn header-btn">
              Rise Above
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClippedGradientHeader;
