import React from "react";
import TeamPicOne from "../../assets/images/team-photo-one.jpg";
// import TeamPicOne from "src/assets/images/team-photo-one.jpg";

const WhyUs = () => {
  return (
    <section className="why-us-wrapper">
      <div className="container">
        <div className="row">
          <div className="why-us-header">
            <h2 className="gradient-secondary-header">
              We are your best choice
            </h2>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="why-us-container">
              <img
                src={TeamPicOne}
                alt="Gradient Ascent"
                className="why-us-image"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 why-us-content-list-main">
            <div className="row">
              <div className="why-us-content-list col-sm-12 col-md-6">
                <h4 className="why-us-header-secondary">
                  Rapid Software Delivery
                </h4>
                <p>
                  We aim to focus on the your business needs with continuous
                  communication to deliver quality code on time.
                </p>
              </div>
              <div className="why-us-content-list col-sm-12 col-md-6">
                <h4 className="why-us-header-secondary">
                  Competitive Day Rates
                </h4>
                <p>
                  To offer our services and solutions at a cost lower than the
                  industry standard, is one of our core philosophies.
                </p>
              </div>
              <div className="why-us-content-list col-sm-12 col-md-6">
                <h4 className="why-us-header-secondary">
                  Apps for Any Platforms
                </h4>
                <p>
                  From mobile apps to eCommerce website, we offer a wide range
                  of solutions that never compromises quality.
                </p>
              </div>
              <div className="why-us-content-list col-sm-12 col-md-6">
                <h4 className="why-us-header-secondary">
                  Personalized Consultation
                </h4>
                <p>
                  We want to understand you as thoroughly as possible to most
                  effectively recommend what's best for your business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
