import React from "react";
import TechStackBG from "../../assets/images/tech-stack.jpg";

const OurTechStack = () => {
  const techStackBG = {
    backgroundImage: `url(${TechStackBG})`,
    height: "75vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    clipPath: `polygon(0 0, 100% 10%, 100% 100%, 0 90%)`,
    backgroundPosition: "center",
    opacity: 0.8,
  };
  return (
    <div style={techStackBG}>
      <div className="gradient-image-container">
        <div className="container">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="tech-stack-header">
              <h2 className="tech-stack-head">
                Invest in technology that works
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-6 col-sm-4">
              <div className="tech-stack-icon-wrap">
                <i className="fa-brands fa-figma tech-stack-icon"></i>
                <h4>FIGMA</h4>
              </div>
            </div>
            <div className="col-xs-6 col-sm-4">
              <div className="tech-stack-icon-wrap">
                <i className="fa-brands fa-js tech-stack-icon"></i>
                <h4>Javascript</h4>
              </div>
            </div>
            <div className="col-xs-6 col-sm-4">
              <div className="tech-stack-icon-wrap">
                <i className="fa-brands fa-react tech-stack-icon"></i>
                <h4>React</h4>
              </div>
            </div>

            <div className="col-xs-6 col-sm-4">
              <div className="tech-stack-icon-wrap">
                <i className="fa-brands fa-vuejs tech-stack-icon"></i>
                <h4>Vue.JS</h4>
              </div>
            </div>

            <div className="col-xs-6 col-sm-4">
              <div className="tech-stack-icon-wrap">
                <i className="fa-brands fa-swift tech-stack-icon"></i>
                <h4>Swift</h4>
              </div>
            </div>
            <div className="col-xs-6 col-sm-4">
              <div className="tech-stack-icon-wrap">
                <i className="fa-brands fa-node tech-stack-icon"></i>
                <h4>Node.JS</h4>
              </div>
            </div>
            {/* -- */}
            {/* <div className="col-xs-6 col-sm-3">
              <div className="tech-stack-icon-wrap">
                <i className="fa-brands fa-angular tech-stack-icon"></i>
                <h4>Angular</h4>
              </div>
            </div> 
            */}
            {/* 
            <div className="col-xs-6 col-sm-3">
              <div className="tech-stack-icon-wrap">
                <i className="fa-brands fa-java tech-stack-icon"></i>
                <h4>Java</h4>
              </div>
            </div> 
            */}
            {/* -- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTechStack;
