import React from "react";
import Mountains from "../../assets/images/gradient-ascent-Mountains.jpg";

const ClippedMountains = () => {
  const mountainBG = {
    backgroundImage: `url(${Mountains})`,
    height: "85vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    clipPath: `polygon(0 0, 100% 10%, 100% 100%, 0 90%)`,
    backgroundPosition: "center",
    opacity: 0.8,
  };
  return (
    <div style={mountainBG}>
      <div className="gradient-image-container">
        <div className="content-wrapper container">
          <div className="row call-to-action">
            <div className="col-sm-12 col-lg-12">
              <div className="head-wrap">
                <h1 className="content-main-heading">
                  Exquisitely Built Websites &amp; Apps
                </h1>
              </div>
            </div>
            <div className="col-sm-12 col-lg-12">
              <div className="sub-heading">
                <h2 className="sub-heading-one">
                  Interactive, imaginative &amp; swift applications...
                </h2>
                <h2 className="sub-heading-two">
                  we deliver work with impact.
                </h2>
              </div>
            </div>

            <div className="col-sm-12 col-lg-6">
              <div className="button-container">
                <a href="#contact-us" className="btn header-btn">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClippedMountains;
