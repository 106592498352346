import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faGooglePlusG,
} from "@fortawesome/free-brands-svg-icons";
import GradientButton from "../General/GradientButton";
import { useNavigate } from "react-router-dom";

const Navigation = () => {
  const BREAKPOINT = 1000;
  const navigate = useNavigate();
  const menuButton = useRef(null);
  const mySidebar = useRef(null);
  const navigationContainer = useRef(null);
  const navigationFooter = useRef(null);

  const leftPanel = useRef(null);
  const rightPanel = useRef(null);

  const nestedLeftPanel = useRef(null);
  const nestedRightPanel = useRef(null);
  const backButton = useRef(null);

  const [menuItemInFocus, setMenuItemInFocus] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const body = Array.from(document.getElementsByTagName("body"))[0];

  // detect window dimensions
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((size) => {
      if (!size[0].contentRect.width && menuOpened) {
        navigationContainer.current.style.top = `calc(50% - ${navigationFooter.current.offsetHeight}px)`;

        // menu is opened
        if (dimensions.width > BREAKPOINT) {
          leftPanel.current.classList.add("width-60");
          rightPanel.current.classList.add("width-40");
          nestedLeftPanel.current.classList.add("width-100");
          nestedRightPanel.current.classList.add("width-0");
        } else {
          leftPanel.current.classList.add("width-0");
          rightPanel.current.classList.add("width-100");
          nestedLeftPanel.current.classList.add("width-100");
          nestedRightPanel.current.classList.add("width-0");
        }
      }

      if (!size[0].contentRect.width && !menuOpened) {
        // menu is closed
        removeWidthClasses();
        if (menuItemInFocus) {
          setMenuItemInFocus(false);
          menuItemInFocus.classList.remove("nav-item-in-focus");
        }
      }
    });

    resizeObserver.observe(mySidebar.current);

    return () => resizeObserver.disconnect();
  }, [mySidebar, menuItemInFocus, menuOpened, dimensions.width]);

  // set nested menu item in focus to false when nested menu is closed
  useEffect(() => {
    const resizeObserver = new ResizeObserver((size) => {
      !size[0].contentRect.width &&
        menuOpened &&
        menuItemInFocus &&
        ![...menuItemInFocus.classList].includes("nav-item-in-focus") &&
        setMenuItemInFocus(false);
    });

    resizeObserver.observe(nestedRightPanel.current);

    return () => resizeObserver.disconnect();
  }, [menuOpened, menuItemInFocus]);

  // handle resize
  const handleResize = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });

    navigationContainer.current.style.top = `calc(50% - ${navigationFooter.current.offsetHeight}px)`;

    if (dimensions.width > BREAKPOINT) {
      removeWidthClasses();

      if (menuItemInFocus) {
        leftPanel.current.classList.add("width-0");
        rightPanel.current.classList.add("width-100");
        nestedLeftPanel.current.classList.add("width-40");
        nestedRightPanel.current.classList.add("width-60");
        backButton.current.classList.add("opacity-100");
      } else {
        leftPanel.current.classList.add("width-60");
        rightPanel.current.classList.add("width-40");
        nestedLeftPanel.current.classList.add("width-100");
        nestedRightPanel.current.classList.add("width-0");
      }
    } else {
      removeWidthClasses();

      if (menuItemInFocus) {
        leftPanel.current.classList.add("width-0");
        rightPanel.current.classList.add("width-100");
        nestedLeftPanel.current.classList.add("width-0-transparent");
        nestedRightPanel.current.classList.add("width-100");
        backButton.current.classList.add("opacity-100");
      } else {
        leftPanel.current.classList.add("width-0");
        rightPanel.current.classList.add("width-100");
        nestedLeftPanel.current.classList.add("width-100");
        nestedRightPanel.current.classList.add("width-0");
      }
    }
  };

  // open nav
  const openNav = () => {
    setMenuOpened(true);
    menuButton.current.style.display = "none";
    mySidebar.current.style.width = "100%";
    navigationContainer.current.style.opacity = "1";
    navigationContainer.current.style.top = `calc(50% - ${navigationFooter.current.offsetHeight}px)`;
    body.style.overflow = "hidden";
  };

  // close nav
  const closeNav = async () => {
    setMenuOpened(false);
    menuButton.current.style.display = "inline-block";
    mySidebar.current.style.width = "0px";
    navigationContainer.current.style.opacity = "0";
    body.style.overflow = "auto";
  };

  const onMenuLinkItemClicked = (e) => {
    e.target.classList.add("nav-item-in-focus");
    setMenuItemInFocus(e.target);

    if (menuItemInFocus) {
      menuItemInFocus.classList.remove("nav-item-in-focus");
    }

    removeWidthClasses();
    leftPanel.current.classList.add("width-0");
    rightPanel.current.classList.add("width-100");

    if (dimensions.width > BREAKPOINT) {
      nestedLeftPanel.current.classList.add("width-40");
      nestedRightPanel.current.classList.add("width-60");
      backButton.current.classList.add("opacity-100");
    } else {
      if (menuItemInFocus) {
        leftPanel.current.classList.add("width-0");
        rightPanel.current.classList.add("width-100");
        nestedLeftPanel.current.classList.add("width-100");
        nestedRightPanel.current.classList.add("width-0");
      } else {
        leftPanel.current.classList.add("width-0");
        rightPanel.current.classList.add("width-100");
        nestedLeftPanel.current.classList.add("width-0-transparent");
        nestedRightPanel.current.classList.add("width-100");
        backButton.current.classList.add("opacity-100");
      }
    }
  };

  const closeNestedMenu = (e) => {
    if (!menuItemInFocus) return;

    if (body.offsetWidth > BREAKPOINT) {
      removeWidthClasses();
      leftPanel.current.classList.add("width-60");
      rightPanel.current.classList.add("width-40");
      nestedLeftPanel.current.classList.add("width-100");
      nestedRightPanel.current.classList.add("width-0");
    } else {
      removeWidthClasses();
      leftPanel.current.classList.add("width-0");
      rightPanel.current.classList.add("width-100");
      nestedLeftPanel.current.classList.add("width-100");
      nestedRightPanel.current.classList.add("width-0");
    }

    menuItemInFocus.classList.remove("nav-item-in-focus");
  };

  const removeWidthClasses = (elements) => {
    elements = elements
      ? elements
      : [leftPanel, rightPanel, nestedLeftPanel, nestedRightPanel, backButton];

    for (let element of elements) {
      for (let _class of element.current.classList) {
        (_class.includes("width") || _class.includes("opacity")) &&
          element.current.classList.remove(_class);
      }
    }
  };

  const renderMenuItemContent = () => {
    switch (menuItemInFocus.innerHTML) {
      case "HOME":
        return (
          <div className="nested-item-content">
            <div className="absolute-nested-nav-item">
              <h2>{menuItemInFocus.innerHTML}</h2>
              <h4>The starting point...</h4>
              <br />
              <ul>
                <li>Bring your creative vision to life</li>
                <li>with the right tech team.</li>
                <li>Explore our options</li>
                <li>from web applications to product design.</li>
              </ul>

              <GradientButton
                className="header-btn"
                text={`Go to ${menuItemInFocus.innerHTML}`}
                onClick={async () => {
                  await closeNav();
                  navigate("/");
                }}
              />
            </div>
          </div>
        );
      case "ABOUT":
        return (
          <div className="nested-item-content">
            <h2>{menuItemInFocus.innerHTML}</h2>
            <h4>We make awesome software!</h4>
            <br />
            <ul>
              <li>Read about our company story,</li>
              <li>mission and purpose.</li>
              <li>Find out what makes us different</li>
            </ul>

            {/* 
            <GradientButton
              className="header-btn"
              text={`Go to ${menuItemInFocus.innerHTML}`}
              onClick={() => {
                closeNav();
                navigate("/about");
              }}
            /> 
            */}
          </div>
        );
      case "WORK":
        return (
          <div className="nested-item-content">
            <h2>{menuItemInFocus.innerHTML}</h2>
            <h4>The proof is in the pudding...</h4>
            <br />
            <ul>
              <li>Check out our past work</li>
              <li>including mobile apps, custom CRMs</li>
              <li>and much more to come.</li>
            </ul>

            {/* 
            <GradientButton
              className="header-btn"
              text={`Go to ${menuItemInFocus.innerHTML}`}
              onClick={() => {
                closeNav();
                navigate("/work");
              }}
            /> 
            */}
          </div>
        );
      case "SERVICES":
        return (
          <div className="nested-item-content">
            <h2>{menuItemInFocus.innerHTML}</h2>
            <h4>We do things...</h4>
            <br />
            <ul>
              <li>Need a web or mobile application?</li>
              <li>What about software consulting?</li>
              <li>We have everything you need</li>
              <li>to get your business off the ground.</li>
            </ul>

            {/* 
            <GradientButton
              className="header-btn"
              text={`Go to ${menuItemInFocus.innerHTML}`}
              onClick={() => {
                closeNav();
                navigate("/services");
              }}
            /> 
            */}
          </div>
        );
      case "BLOG":
        return (
          <div className="nested-item-content">
            <h2>{menuItemInFocus.innerHTML}</h2>
            <h4>Yet another web log..</h4>
            <br />
            <ul>
              <li>Every business has a story behind their story.</li>
              <li>Read up on our journey,</li>
              <li>and lessons we've learned</li>
              <li>along the way.</li>
            </ul>

            {/* 
            <GradientButton
              className="header-btn"
              text={`Go to ${menuItemInFocus.innerHTML}`}
              onClick={() => {
                closeNav();
                navigate("/blog");
              }}
            /> 
            */}
          </div>
        );
      default:
        break;
    }
  };

  return (
    <nav className="navigation-container">
      <div id="main">
        <button ref={menuButton} className="openMenuButton" onClick={openNav}>
          &#9776;
        </button>
      </div>

      <div ref={mySidebar} id="mySidebar" className="sidebar">
        <button className="closeButton" onClick={closeNav}>
          &times;
        </button>

        <div className="nav-content">
          <div
            className="navigation-content-container"
            ref={navigationContainer}
          >
            <div className="left-panel" ref={leftPanel}></div>
            <div className="right-panel" ref={rightPanel}>
              <div className="nested-left-menu" ref={nestedLeftPanel}>
                <ul className="nested-left-menu-content">
                  <li
                    className="navigation__item"
                    onClick={onMenuLinkItemClicked}
                  >
                    <div className="navigation__link">HOME</div>
                  </li>
                  <li
                    className="navigation__item"
                    onClick={onMenuLinkItemClicked}
                  >
                    <div className="navigation__link">ABOUT</div>
                  </li>
                  <li
                    className="navigation__item"
                    onClick={onMenuLinkItemClicked}
                  >
                    <div className="navigation__link">WORK</div>
                    {/* 
                    <Link to="/work" className="navigation__link">
                      WORK
                    </Link> 
                    */}
                  </li>
                  <li
                    className="navigation__item"
                    onClick={onMenuLinkItemClicked}
                  >
                    <div className="navigation__link">SERVICES</div>
                    {/* 
                    <Link to="/services" className="navigation__link">
                      SERVICES
                    </Link> 
                    */}
                  </li>
                  <li
                    className="navigation__item"
                    onClick={onMenuLinkItemClicked}
                  >
                    <div className="navigation__link">BLOG</div>
                    {/* 
                    <Link to="/blog" className="navigation__link">
                      BLOG
                    </Link> 
                    */}
                  </li>
                </ul>
              </div>
              <div className="nested-right-menu" ref={nestedRightPanel}>
                <div className="nested-right-menu-content">
                  <i
                    ref={backButton}
                    className="fa fa-arrow-left back-button"
                    aria-hidden="true"
                    onClick={closeNestedMenu}
                  />
                  {renderMenuItemContent()}
                </div>
              </div>
            </div>
          </div>

          <div className="navigation-content-footer" ref={navigationFooter}>
            <div className="navigation-footer-column-lft">
              <h3 className="social-title">Social</h3>

              <div className="social-links">
                <Link className="fa-lg">
                  <FontAwesomeIcon icon={faFacebookF} />
                </Link>
                <Link className="fa-lg">
                  <FontAwesomeIcon icon={faTwitter} />
                </Link>
                <Link className="fa-lg">
                  <FontAwesomeIcon icon={faGooglePlusG} />
                </Link>
              </div>
            </div>

            <div className="navigation-footer-column-rgt">
              <a href="#contact-us">
                <GradientButton
                  className="header-btn"
                  text={"Get an Estimate"}
                  onClick={async () => {
                    await closeNav();
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
