import React from "react";
import Solutions from "../components/Home/Solutions";
import GradientOverlay from "../components/Home/GradientOverlay";
// import PricingCards from "../components/Home/PricingCards";
import ClippedMountains from "../components/Home/ClippedMountains";
import ClippedGradientHeader from "../components/Home/ClippedGradientHeader";
import ContactForm from "../components/Home/ContactForm";
import ToastMessage from "../components/General/ToastMessage";
import WhyUs from "../components/Home/WhyUs";
import OurTechStack from "../components/Home/OurTechStack";
import OurProcess from "../components/Home/OurProcess";
import Layout from "../components/Layout/Layout";
import Navigation from "../components/Home/Navigation";

const Home = () => {
  return (
    <Layout
      title="Home"
      showHeader={false}
      content={
        <>
          <Navigation />
          <ClippedGradientHeader />
          <Solutions />
          <ClippedMountains />
          {/* <PricingCards /> */}
          <WhyUs />
          <OurTechStack />
          {/* <OurProcess /> */}
          {/* <GradientOverlay /> */}
          <ContactForm />
          <ToastMessage />
        </>
      }
      showFooter={true}
    />
  );
};

export default Home;
