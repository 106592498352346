import React from "react";
import Layout from "../components/Layout/Layout";
import ComingSoon from "../components/Layout/ComingSoon";
import { motion } from "framer-motion";

const Blog = () => {
  return (
    <Layout
      title="Blog"
      showHeader={true}
      content={
        <div>
          <ComingSoon />
        </div>
      }
      showFooter={true}
    />
  );
};

export default Blog;
