import React from "react";

const GradientButton = ({ className, text, onClick }) => {
  return (
    <div className={`btn ${className}`} onClick={onClick}>
      {text}
    </div>
  );
};

export default GradientButton;
