import React, { useState } from "react";
import axios from "axios";
import ToastMessage from "../General/ToastMessage";
const API_URL = "https://gradient-ascent-server.herokuapp.com";

const ContactForm = () => {
  const defaultContactFormState = {
    name: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    summary: "",
  };
  const [contactInfo, setContactInfo] = useState({
    ...defaultContactFormState,
  });
  const [message, setMessage] = useState(null);

  const handleChange = (event) => {
    setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
  };

  const sendEmail = async () => {
    const config = { headers: { "Content-Type": "application/json" } };
    const url = `${API_URL}/api/email/add-ga-user`;
    const campaign = "ga-website-contact-us-form";

    try {
      await axios.post(
        url,
        {
          campaign: campaign,
          name: contactInfo.name,
          phoneNumber: contactInfo.phoneNumber,
          email: contactInfo.email,
          companyName: contactInfo.companyName,
          summary: contactInfo.summary,
        },
        config
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // const url = `${API_URL}/api/email/add-ga-user`;
      const url =
        "https://gradient-ascent-server.herokuapp.com/api/contact-us/new-contact";

      const body = { ...contactInfo };

      await axios.post(url, body);
      await sendEmail();

      setMessage(
        "Thank you for your submission! We will reach out to you soon."
      );

      setContactInfo(defaultContactFormState);
    } catch (error) {
      console.log(error);
      setMessage(error.response.data.error);
    }

    const toastMessage = document.getElementById("toast-message");
    toastMessage.className = "show";

    setTimeout(function () {
      toastMessage.className = toastMessage.className.replace("show", "");
      setMessage(null);
    }, 3000);
  };

  return (
    <section className="contact-details" id="contact-us">
      <ToastMessage message={message} />
      <div className="contact-content container">
        <div className="contact-header">
          <h2 className="gradient-secondary-header">Reach Out to Us!</h2>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              {/* contact form */}
              <div className="col-lg-12 contact-form-wrapper">
                <div className="col-sm-12 col-md-12 contact-form">
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Your Name"
                        required
                        onChange={handleChange}
                        value={contactInfo.name}
                      />
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <input
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        className="form-control"
                        placeholder="Your Phone Number"
                        // size="20"
                        // minLength="9"
                        // maxLength="14"
                        required
                        onChange={handleChange}
                        value={contactInfo.phoneNumber}
                      />
                    </div>

                    <div className="col-sm-12 col-md-12">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Your Email"
                        // minLength="9"
                        required
                        onChange={handleChange}
                        value={contactInfo.email}
                      />
                    </div>

                    <div className="col-sm-12 col-md-12">
                      <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        className="form-control"
                        placeholder="Your Company Name"
                        required
                        onChange={handleChange}
                        value={contactInfo.companyName}
                      />
                    </div>

                    <div className="col-sm-12 col-md-12">
                      <textarea
                        type="text"
                        id="summary"
                        name="summary"
                        rows="2"
                        className="form-control md-textarea"
                        placeholder="Tell us about your project"
                        required
                        onChange={handleChange}
                        value={contactInfo.summary}
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-12 text-center">
                    <button className="purple-btn mt-2" onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <div className="col-lg-12 contact-item-wrapper">
                <div className="row">
                  <div className="col-sm-12 col-lg-12 contact-item">
                    <h3 className="contact-item-header">Let's Work Together</h3>
                    <p className="mb-0">admin@gradientascent.io</p>
                    <p className="mb-0">(562) 212-8377</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
