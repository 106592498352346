import React from "react";
import Layout from "../components/Layout/Layout";
import ComingSoon from "../components/Layout/ComingSoon";

const Services = () => {
  return (
    <Layout
      title="Services"
      showHeader={true}
      content={
        <>
          <ComingSoon />
        </>
      }
      showFooter={true}
    />
  );
};

export default Services;
