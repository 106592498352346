import React from "react";

const Features = () => {
  return (
    <section className="features-wrapper">
      <div className="container">
        <div className="features-header">
          <h2 className="gradient-secondary-header">
            Unique web design &amp; branding solutions
          </h2>
          <p className="secondary-paragraph">
            Gradient Ascent is a boutique web design and application development
            company that offers customized consulting and bespoke web
            applications. Are you looking for a tech partner to guide you every
            step of the way?
          </p>

          <a href="#contact-us" className="purple-btn mt-2">
            Get An Estimate
          </a>

          {/* <a href="our-services" className="purple-btn mt-2">
            Our Services
          </a> */}
        </div>

        <div className="row individual-features">
          {/* <div className="row flex-items-xs-middle flex-items-xs-center individual-features"> */}
          <div className="col-md-6  col-lg-4">
            <div className="feature-element">
              <div className="content">
                <div className="icon">
                  <i className="fa-brands fa fa-app-store feature-icon"></i>
                </div>
                <h3 className="element-head">
                  Software &amp; Apps for Start-ups
                </h3>
                <p className="element-para">
                  Transcend your competition with custom sites, web apps or
                  mobile apps for your business!
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6  col-lg-4">
            <div className="feature-element">
              <div className="content">
                <div className="icon">
                  <i className="fa-light fa fa-cloud feature-icon"></i>
                </div>
                <h3 className="element-head">Cloud Based Apps</h3>
                <p className="element-para">
                  Hassle free, fast and secure hosting that can be to your
                  price.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6  col-lg-4">
            <div className="feature-element">
              <div className="content">
                <div className="icon">
                  <i className="fa-brands fa-uncharted feature-icon"></i>
                </div>
                <h3 className="element-head">Saas Applications</h3>
                <p className="element-para">
                  Building beautiful and responsive websites that will breathe
                  life into your online presence.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6  col-lg-4">
            <div className="feature-element">
              <div className="content">
                <div className="icon">
                  <i className="fa-brands fa fa-react feature-icon"></i>
                </div>
                <h3 className="element-head">Custom CRMs</h3>
                <p className="element-para">
                  Branding is essential for you to stand out. We offer
                  consultations and will tailor a plan to fit your brand.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6  col-lg-4">
            <div className="feature-element">
              <div className="content">
                <div className="icon">
                  <i className="fa-light fas fa-mobile-screen-button feature-icon"></i>
                </div>
                <h3 className="element-head">
                  Mobile Apps
                  {/* Copywriting &amp; Site Content Management */}
                </h3>
                <p className="element-para">
                  We help you write clear and concise copy for your ads,
                  marketing materials, and websites.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6  col-lg-4">
            <div className="feature-element">
              <div className="content">
                <div className="icon">
                  <i className="fa-light fa fa-circle-nodes feature-icon"></i>
                </div>
                <h3 className="element-head">Enterprise Apps</h3>
                <p className="element-para">
                  Your business needs to be seen. SEO can put your company in
                  front of new customers.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row individual-features"></div>
      </div>
    </section>
  );
};

export default Features;
