import React from "react";

const ComingSoon = () => {
  return (
    <div className="coming-soon">
      <h5>Coming Soon</h5>
    </div>
  );
};

export default ComingSoon;
