import React from "react";
import Layout from "../components/Layout/Layout";
import ComingSoon from "../components/Layout/ComingSoon";

const Work = () => {
  return (
    <Layout
      title="Work"
      showHeader={true}
      content={
        <div>
          <ComingSoon />
        </div>
      }
      showFooter={true}
    />
  );
};

export default Work;
