import React from "react";
import PropTypes from "prop-types";

import Header from "./Header";
import Footer from "./Footer";
import SEO from "@americanexpress/react-seo";
import keywords from "../../seo/keywords";
import { motion } from "framer-motion";

const Layout = ({ title, showHeader, showFooter, content }) => {
  return (
    <>
      <SEO
        title={`Gradient Ascent | Creative Digital Agency | ${title}`}
        description="Results-focused Digital Agency: Gradient Ascent creates SUPERIOR digital experiences to grow your online brands."
        keywords={keywords}
        siteUrl="https://www.gradientascent.io/"
        image={{
          src: "https://gradient-ascent-server.herokuapp.com/images/gradient-logo-192.png",
        }}
      />
      {showHeader && <Header />}
      {content}
      {showFooter && <Footer />}
    </>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  showHeader: PropTypes.bool.isRequired,
  content: PropTypes.element.isRequired,
  showFooter: PropTypes.bool.isRequired,
};

export default Layout;
