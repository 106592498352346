import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="containers">
      <header className="header">
        <h1 className="company-name">LOGO</h1>
      </header>
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="container navi">
          <li>
            <Link to="/">HOME</Link>
          </li>
          <li>
            <Link to="/about">ABOUT</Link>
          </li>
          <li>
            <Link to="/work">WORK</Link>
          </li>
          <li>
            <Link to="/services">SERVICES</Link>
          </li>
          <li>
            <Link to="/blog">BLOG</Link>
          </li>
        </div>
      </nav>
    </div>
  );
};

export default Header;
