import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faBehance,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

import WhiteLogo from "../../assets/images/logo.svg";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-dark">
        <div className="col-6 footer-logo">
          <img
            src={WhiteLogo}
            alt="logo"
            className="white-logo"
            style={{ height: 75, width: 75 }}
          />
        </div>
        <div className="col-6 footer-links">
          Gradient Ascent is a global branding and digital agency that strives
          to build products, services, and eCommerce experiences to help take
          you to your highest point
        </div>
        {/* <div className="col-6 footer-links">DIGITAL AGENCY</div> */}
      </div>

      <div className="footer-light">
        <div className="row">
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div className="copyright">
              &copy; {new Date().getFullYear()} Gradient Ascent.
            </div>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div className="social-media-icons">
              <div className="social-icon">
                <FontAwesomeIcon icon={faFacebookF} />
              </div>
              <div className="social-icon">
                <FontAwesomeIcon icon={faTwitter} />
              </div>
              <div className="social-icon">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </div>
              <div className="social-icon">
                <FontAwesomeIcon icon={faYoutube} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
