import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Work from "./pages/Work";
import AnimateRoutes from "./components/General/AnimateRoutes";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="blog" element={<Blog />} />
        <Route path="services" element={<Services />} />
        <Route path="work" element={<Work />} />
        <Route path="our-services" element={<Work />} />
      </Routes>

      {/* <AnimateRoutes /> */}
    </BrowserRouter>
  );
}

export default App;
